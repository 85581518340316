import { useEffect } from 'react';
import { StoreApi, UseBoundStore } from 'zustand';
import Router from 'next/router';
import QueryString from 'qs';
import filterObjectKeys, { skipUndefinedCondition } from '../filterObjectKeys';
import useQueryString from '@/hooks/useQueryString';

export function createOpenPageHandler<T extends object>(
  store: UseBoundStore<StoreApi<T>>,
  {
    pathname,
    getQsFromState,
  }: {
    pathname: string;
    getQsFromState: (partialState: Partial<T>) => QueryString.ParsedQs;
  }
) {
  function openPage(partialState: Partial<T>, pathParams: Record<string, string> = {}) {
    store.setState(filterObjectKeys(partialState, skipUndefinedCondition(partialState))); // NOTE: this is needed to make this function work when it is called from within the same page (query string will not update zustand state in this case)
    Router.push(makePageHref(partialState, pathParams));
  }

  function makePageHref(partialState: Partial<T>, pathParams: Record<string, string> = {}) {
    return `${replacePathParams(pathname, pathParams)}${QueryString.stringify(getQsFromState(partialState), {
      addQueryPrefix: true,
    })}`;
  }

  return {
    openPage,
    makePageHref,
  };
}

export function createQueryStringStoreSync<T>(
  store: UseBoundStore<StoreApi<T>>,
  {
    getQsFromState,
    getStateFromQs,
    defaultState,
  }: {
    getQsFromState: (partialState: Partial<T>) => QueryString.ParsedQs;
    getStateFromQs: (query: QueryString.ParsedQs, defaultState: T) => T;
    defaultState: T;
  }
) {
  function QueryStringStoreSync() {
    const { query, setQueryParams } = useQueryString();
    useEffect(() => {
      const stateFromParams = getStateFromQs(query, defaultState);
      store.setState(stateFromParams);
      // eslint-disable-next-line react-hooks/exhaustive-deps -- only need this called once
    }, []);

    useEffect(() => {
      return store.subscribe((state) => {
        setQueryParams(getQsFromState(state));
      });
    }, [setQueryParams]);

    return null;
  }

  return {
    QueryStringStoreSync,
  };
}

function replacePathParams(path: string, pathParams: Record<string, string>) {
  return path.replace(/:(\w+)/g, (match, p1) => pathParams[p1] || match);
}
